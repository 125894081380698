import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import informacionSocio from "./SectionsEdit/InformacionSocio";
import DomicilioSocio from "./SectionsEdit/DomicilioSocio";
import PlanSocio from "./SectionsEdit/PlanSocio";
import descuentosSocio from "./SectionsEdit/DescuentosSocio";
import aptoMedicoSocio from "./SectionsEdit/AptoMedicoSocio";
import CustomTabbedForm from "../../Components/Form/CustomTabbedForm";
import { BiUser } from "react-icons/bi";
import { BiHome } from "react-icons/bi";
import { CgGym } from "react-icons/cg";
import { BsFileMedical } from "react-icons/bs";
import { MdOutlineLocalOffer } from "react-icons/md";
import CreateForm from "../../Components/Form/CreateForm";
import domicilioSocio from "./SectionsEdit/DomicilioSocio";
import planSocio from "./SectionsEdit/PlanSocio";
import { uppercaseString } from "../helpers/helpFunctions";

const SocioCreateEdit = ({ isEdit, permissions, ...props }) => {
  const [planCadena, setPlanCadena] = useState(false);

  const record = useRecordContext();
  const isSuperadmin = permissions === "superadmin";

  const transform = (v) => {
    const ret = {
      ...v,
      nombre: uppercaseString(v.nombre.trim()),
      apellido: uppercaseString(v.apellido.trim()),
      email: v.email.trim(),
      domicilio: {
        ...v.domicilio,
        calle: uppercaseString(v.domicilio.calle),
        localidad: uppercaseString(v.domicilio.localidad),
      },
    };
    if (!ret?.domicilio?.apto_lote) delete ret.domicilio.apto_lote;
    return ret;
  };

  const defaultValues = {
    preferred_payment_method: "efectivo",
    status: "activo",
  };
  const tabs = [
    {
      component: informacionSocio(record),
      label: "Información",
      icon: <BiUser />,
    },
    {
      component: domicilioSocio(),
      label: "Domicilio",
      icon: <BiHome />,
    },
    {
      component: planSocio(null, setPlanCadena),
      label: "Plan",
      icon: <CgGym />,
    },
    {
      component: aptoMedicoSocio(),
      label: "Apto Medico",
      icon: <BsFileMedical />,
    },
    !isEdit && {
      component: descuentosSocio(planCadena),
      label: "Descuentos",
      icon: <MdOutlineLocalOffer />,
    },
  ];
  return (
    <CreateForm
      transform={transform}
      tabs={tabs}
      defaultValues={defaultValues}
      route={"socios"}
    />
  );
};

export default SocioCreateEdit;
