import React, { useContext, useEffect, useState } from "react";
import {
  Menu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
} from "react-admin";
import MenuContextMerchant from "./MenuContextMerchant";
import { Collapse, Grow, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box, Divider, IconButton, Button } from "@mui/material";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { merchantContext } from "../../../../DataProvider";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";

const MenuCustom = (props) => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name]
  );
  const filteredResources = resources.filter((item) => !item?.options?.hidden);
  const sidebarOpen = useSidebarState();
  const handleChange = () => sidebarOpen[1](window.innerWidth > 600);

  useEffect(() => {
    handleChange();
    window.addEventListener("resize", handleChange);
    return () => window.removeEventListener("resize", handleChange);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleEmail = () => {
    window.location.href = `mailto:${process.env.REACT_APP_SOPORTE_EMAIL}`;
    handleCloseMenu();
  };

  return (
    <Menu {...props}>
      <MenuContextMerchant />
      <Divider style={{ margin: "3px 18px 15px 18px" }} />
      <Box
        id="scrollableDivJoyRide"
        sx={{
          height: "63vh",
          overflow: "scroll",
          "&::-webkit-scrollbar": {
            width: "5px",
            borderRadius: "12px",
          },
        }}
      >
        {filteredResources.map((resource, index) => {
          if (resource.options?.isMenuParent) {
            return (
              <>
                <SectionParent
                  id={index}
                  resource={resource}
                  resources={filteredResources}
                />
              </>
            );
          } else {
            if (resource.hasList && !resource.options?.menuParent) {
              return (
                <>
                  <MenuItemLink
                    id="dashboard"
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                      (resource.options && resource.options.label) ||
                      resource.name
                    }
                    leftIcon={resource.icon ? <resource.icon /> : null}
                    sidebarIsOpen={open}
                    style={{
                      color: grey[600],
                      padding: "6px 16px",
                      margin: sidebarOpen[0] ? "0 12px" : "3px",
                      fontWeight: "600",
                      borderRadius: "12px",
                      fontSize: "0.85rem",
                      lineHeight: "1.75",
                      letterSpacing: "0.01em",
                    }}
                  />
                </>
              );
            }
          }
        })}
      </Box>
      <Divider sx={{ margin: "0px 18px 15px 18px" }} />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          zIndex: 1500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {isMenuOpen && (
            <Grow in={isMenuOpen} timeout={800}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#23577E",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: "70px",
                  position: isMenuOpen ? "static" : "absolute",
                }}
                onClick={handleCloseMenu}
              >
                <CloseIcon sx={{ color: "white", fontSize: "20px" }} />
              </Box>
            </Grow>
          )}

          {!isMenuOpen && (
            <Grow in={!isMenuOpen} timeout={800}>
              <Button
                sx={{
                  height: "50px",
                  width: "120px",
                  color: "white",
                  marginTop: "200px",
                  padding: "20px 15px",
                  fontSize: "14px",
                  fontWeight: "600",
                  borderRadius: "50px",
                  cursor: "pointer",
                  outline: "none",
                  textTransform: "none",
                  backgroundColor: "#23577E",
                  "&:hover": {
                    backgroundColor: "#23577E",
                  },
                  transform: "scale(1.3)",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  position: "absolute",
                }}
                onClick={handleOpenMenu}
              >
                <ModeCommentOutlinedIcon sx={{ fontSize: "14px" }} />
                <span style={{ textTransform: "capitalize" }}>soporte</span>
              </Button>
            </Grow>
          )}
        </Box>

        {isMenuOpen && (
          <Box
            sx={{
              position: "absolute",
              height: "50px",
              marginLeft: "130px",
              width: "auto",
              marginBottom: "5%",
              transform: "translate(-50%, -50%)",
              zIndex: 1200,
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            <IconButton
              sx={{
                height: "50px",
                width: "50px",
                color: "#ffffff",
                backgroundColor: "#23577E",
                "&:hover": {
                  backgroundColor: "#23577E",
                },
                transform: "scale(1.2)",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
              }}
              onClick={handleEmail}
            >
              <EmailOutlinedIcon />
            </IconButton>

            <a
              href={`${process.env.REACT_APP_SOPORTE_TELEFONO}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{
                  height: "50px",
                  width: "50px",
                  color: "#ffffff",
                  backgroundColor: "#23577E",
                  "&:hover": {
                    backgroundColor: "#23577E",
                  },
                  transform: "scale(1.2)",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                }}
                // onClick={handleWhatsApp}
              >
                <WhatsAppIcon />
              </IconButton>
            </a>
          </Box>
        )}
      </Box>
    </Menu>
  );
};

const SectionParent = ({ resource, resources }) => {
  //Llamo al context de Merchant
  const { getMerchant } = useContext(merchantContext);
  const merchantSelected = getMerchant();

  const [open, setOpen] = useState(true);
  const sidebarOpen = useSidebarState();
  const [buttonClicked, setButtonClicked] = useState(false);

  return (
    <Box
      style={{
        marginBottom: open && "6px",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 12px",
          padding: "9px 9px 9px 15px",
          cursor: "pointer",
          borderRadius: "12px",
          // '&:hover': {
          //   backgroundColor: grey[200]
          // }
        }}
        onClick={() => setOpen(!open)}
      >
        {sidebarOpen[0] && (
          <>
            <Typography
              variant="subtitle2"
              style={{
                color: grey[600],
                letterSpacing: "0.05em",
                fontFamily: "Raleway, sans-serif",
                fontWeight: "500",
                fontSize: "0.78rem",
              }}
            >
              {resource.name}
            </Typography>
            {open ? (
              <MdExpandLess style={{ color: grey[600] }} />
            ) : (
              <MdExpandMore style={{ color: grey[600] }} />
            )}
          </>
        )}
      </Box>
      <Collapse in={!sidebarOpen[0] || open} unmountOnExit>
        {resources.map((item) => {
          if (
            item.options?.menuParent &&
            item.options?.menuParent === resource.name
          ) {
            // //re dirrecion de planes si se necesiita
            // if (
            //   item.name == "planes" &&
            //   item.options.menuParent == "ADMINISTRATIVO"
            // ) {
            //   return (
            //     <div onClick={() => { setButtonClicked(true) }}>
            //       <MenuItemLink
            //         className={item?.name}
            //         key={item.name}
            //         to={`/${item.name}`}
            //         primaryText={
            //           (item.options && item.options.label) || item.name
            //         }
            //         leftIcon={item.icon ? <item.icon /> : null}
            //         sidebarIsOpen={open}
            //         style={{
            //           margin: sidebarOpen[0] ? "0px 12px 0 24px" : "0px 3px",
            //           color: grey[600],
            //           padding: "6px 16px",
            //           fontWeight: "600",
            //           borderRadius: "12px",
            //           fontSize: "0.85rem",
            //           lineHeight: "1.75",
            //           letterSpacing: "0.01em",
            //           "&.active": {
            //             backgroundColor: grey[200],
            //             color: "#5584AC",
            //             "& .MuiListItemIcon-root": {
            //               color: "#5584AC",
            //             },
            //           },
            //         }}
            //       />
            //     </div>
            //   );
            // }
            return (
              <MenuItemLink
                className={item?.name}
                key={item.name}
                to={`/${item.name}`}
                primaryText={(item.options && item.options.label) || item.name}
                leftIcon={item.icon ? <item.icon /> : null}
                sidebarIsOpen={open}
                style={{
                  margin: sidebarOpen[0] ? "0px 12px 0 24px" : "0px 3px",
                  color: grey[600],
                  padding: "6px 16px",
                  fontWeight: "600",
                  borderRadius: "12px",
                  fontSize: "0.85rem",
                  lineHeight: "1.75",
                  letterSpacing: "0.01em",
                  "&.active": {
                    backgroundColor: grey[200],
                    color: "#5584AC",
                    "& .MuiListItemIcon-root": {
                      color: "#5584AC",
                    },
                  },
                }}
              />
            );
          }
        })}
      </Collapse>
    </Box>
  );
};

export default MenuCustom;
