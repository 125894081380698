import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
} from "@mui/material";
import CardBasic from "../../../Components/UI/Show/CardBasic";
import DotDivider from "../../../Components/UI/DotDivider";
import { CgCreditCard } from "react-icons/cg";
import ModalCustom from "../../../Components/UI/ModalCustom";
import { useRecordContext, useRefresh, useUpdate } from "react-admin";
import ModalWhitelist from "../../../Components/UI/ModalWhitelist";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InputFile from "../../../Components/CustomFields/InputFile";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { fontTextInfoKey, styleSpanPlan } from "../../../Theme/useStyle";
import {
  createNotification,
  getPermissions,
  getToken,
  handleClickCopy,
} from "../../helpers/helpFunctions";
import { TextField as TextFieldMui } from "@mui/material";
import { merchantCadena, merchantContext } from "../../../DataProvider";
const styleListDnis = {
  display: "flex",
  flexWrap: "wrap",
  border: "1px solid #dedede",
  padding: "20px",
  borderRadius: "10px",
  position: "relative",
};

const WhitelistCard = () => {
  const record = useRecordContext();
  const { getMerchant } = useContext(merchantContext);
  const permissions = getPermissions();
  const [whiteListMinimal, setWhitelistMinimal] = useState(
    record.whitelist?.length > 10
      ? record.whitelist?.slice(0, 10)
      : record.whitelist
  );
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [search, setSearch] = useState(null);
  const onlyNumbers = /^[0-9]*$/;
  const [update, { isLoading, loaded, error }] = useUpdate();
  const refresh = useRefresh();

  const handleSearch = (e) => {
    const value = e.target.value;
    const passTest = onlyNumbers.test(value);
    if (value == "") {
      setSearch("");
      setWhitelistMinimal(
        record.whitelist?.length > 12
          ? record.whitelist?.slice(0, 12)
          : record.whitelist
      );
    } else if (passTest) {
      setSearch(value);
      if (value?.length >= 6) {
        const result = record.whitelist.filter((item) => item.includes(value));
        setWhitelistMinimal(result);
      }
    }
  };

  const handlerUpdate = (whils) => {
    update("corporativo", {
      id: record.id,
      data: { whitelist: whils },
      previousData: record,
    });
  };

  const deleteHandler = (dni) => {
    const filterWhitelist = record.whitelist.filter(
      (item, i) => i != record.whitelist.indexOf(dni)
    );
    setWhitelistMinimal(filterWhitelist.slice(0, 12));
    handlerUpdate(filterWhitelist);
  };

  useEffect(() => {
    setWhitelistMinimal(
      record.whitelist?.length > 12
        ? record.whitelist?.slice(0, 12)
        : record.whitelist
    );
  }, [record]);

  useEffect(() => {
    if (loaded) {
      refresh();
    }
    setWhitelistMinimal(
      record.whitelist?.length > 12
        ? record.whitelist?.slice(0, 12)
        : record.whitelist
    );
  }, [loaded]);
  const disableActions = useMemo(() => {
    const token = getToken();
    if (
      permissions === "admin" &&
      !token.merchant.find((item) => item === merchantCadena) &&
      record.merchant_id !== getMerchant().id
    ) {
      return true;
    }
    if (
      record.tipo.includes("cadena") &&
      !["superadmin", "owner"].includes(permissions) &&
      !token.merchant.find((item) => item === merchantCadena)
    ) {
      return true;
    }
    return false;
  }, [getMerchant(), permissions]);

  let popOverText =
    "El formato admitido para poder agregar una lista de DNIS es de .csv, dentro del archivo los DNIS deben estar ubicados unicamente en la primer columna sin puntos ni comas.";

  return (
    <CardBasic title="Whitelist" popOver={popOverText}>
      {record.whitelist?.length > 0 ? (
        <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2">
              DNIs / Pasaportes cargados:{" "}
              <span style={styleSpanPlan}>{record.whitelist?.length}</span>
            </Typography>
            <TextFieldMui
              label="Buscar"
              type="search"
              size="small"
              variant="outlined"
              style={{ width: "140px" }}
              onChange={(e) => handleSearch(e)}
              value={search}
              error={search?.length > 8 ? true : false}
            />
          </Box>
          <Box style={styleListDnis}>
            {record?.whitelist?.length > 0 && whiteListMinimal?.length > 0 ? (
              whiteListMinimal?.map((item) => (
                <Box
                  key={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "150px",
                      justifyContent: "space-between",
                    }}
                  >
                    <DotDivider />
                    <CgCreditCard
                      style={{
                        fontSize: "16px",
                        marginRight: "5px",
                        marginBottom: "2px",
                      }}
                    />
                    <Typography variant="subtitle2">{item}</Typography>
                    <IconButton
                      aria-label="delete"
                      color="error"
                      disabled={disableActions}
                      onClick={() => deleteHandler(item)}
                    >
                      <DeleteOutlinedIcon style={{ fontSize: "20px" }} />
                    </IconButton>
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "15px 0",
                  width: "100%",
                }}
              >
                <Typography style={{ fontSize: "14px", color: "#64648C" }}>
                  No se encuentra el DNI /Pasaporte buscado
                </Typography>
              </Box>
            )}
            <Box style={{ position: "absolute", bottom: "5px", right: "15px" }}>
              <Button
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  color: "#64648C",
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
                variant="text"
                disabled={disableActions}
                onClick={() => setOpenView(true)}
              >
                Ver Todos / Eliminar
              </Button>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <Button
              disabled={disableActions}
              variant="contained"
              size="small"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
            <Button
              disabled={disableActions}
              variant="contained"
              size="small"
              onClick={() => setOpenList(true)}
            >
              Agregar Lista
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <Typography style={{ fontSize: "13px", color: "#64648C" }}>
            No se encuentran DNIs / Pasaportes cargados
          </Typography>
          <Box style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpen(true)}
              disabled={disableActions}
            >
              Agregar
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={disableActions}
              onClick={() => setOpenList(true)}
            >
              Agregar Lista
            </Button>
          </Box>
        </Box>
      )}
      <ModalListDni open={openList} setOpen={setOpenList} record={record} />
      <ModalWhitelist record={record} setOpen={setOpenView} open={openView} />
      <ModalAddDni open={open} setOpen={setOpen} record={record} />
    </CardBasic>
  );
};

const ModalListDni = ({ open, setOpen }) => {
  const record = useRecordContext();
  const [value, setValue] = React.useState("agregar");
  const [whitelistSend, setWhitelistSend] = useState(
    record?.whitelist !== null ? record.whitelist : []
  );
  const [info, setInfo] = useState({});
  const [update, { isLoading, loaded, error, data }] = useUpdate();
  const refresh = useRefresh();

  const updateList = (a) => {
    const dnisArray = /\r|\n/.test(a) ? a[0]?.split("\n")?.filter(Boolean) : a;

    if (whitelistSend?.length > 0) {
      let concatArray = whitelistSend.concat(dnisArray);
      const whitelistNoDuplicated = deletDuplicated(concatArray);
      console.log(whitelistNoDuplicated);
      setInfo({ whitelist: whitelistNoDuplicated });
      update("corporativo", {
        id: record.id,
        data: { whitelist: whitelistNoDuplicated },
        previousData: record,
      });
    } else {
      const whitelistNoDuplicated = deletDuplicated(dnisArray);
      console.log(whitelistNoDuplicated);
      setInfo({ whitelist: whitelistNoDuplicated });
      update("corporativo", {
        id: record.id,
        data: { whitelist: whitelistNoDuplicated },
        previousData: record,
      });
    }
  };

  const replaceList = (a) => {
    // Divido la lista entrante por salto de linea
    const dnisArray = /\r|\n/.test(a) ? a[0]?.split("\n")?.filter(Boolean) : a;

    const replaceWhitelist = deletDuplicated(dnisArray);
    setInfo({ whitelist: replaceWhitelist });
    update("corporativo", {
      id: record.id,
      data: { whitelist: replaceWhitelist },
      previousDefault: record,
    });
  };

  const deletDuplicated = (a) => {
    let result = a?.filter((item, index) => {
      return a.indexOf(item) === index;
    });
    return result;
  };

  useEffect(() => {
    if (Boolean(data) && !isLoading) {
      console.log(loaded, data);
      setOpen(false);
      createNotification("success", "Lista agregada con éxito");
      refresh();
    }
    if (error) {
      createNotification(
        "error",
        "No se pudo cargar la lista, por favor intenta de nuevo",
        () => handleClickCopy(info)
      );
    }
  }, [data, error, isLoading]);
  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      onClose={() => setOpen(false)}
      showButtons
      replaceButton={
        <InputFile
          success={value == "agregar" ? updateList : replaceList}
          text="Buscar"
          style={{ margin: "15px 0" }}
          accept=".txt, .xlsx, .xls, .csv"
          startIcon={
            isLoading ? (
              <CircularProgress
                style={{ width: "16px", height: "16px", color: "#fff" }}
              />
            ) : (
              <SearchOutlinedIcon />
            )
          }
          disabled={isLoading}
        />
      }
      title="Agregar o Reemplazar Lista"
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="radioButtonsid"
            name="row-radio-buttons-group"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <Tooltip
              title="Unicamente se agregarán los DNIs / Pasaportes de socios que no estén cargados en tu lista."
              placement="top"
            >
              <FormControlLabel
                value="agregar"
                control={<Radio />}
                label="Agregar"
              />
            </Tooltip>
            <Tooltip
              title="Se borrarán todos los usuarios y serán reemplazados por la lista que estás cargando."
              placement="top"
            >
              <FormControlLabel
                value="reemplazar"
                control={<Radio />}
                label="Reemplazar"
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Box>
    </ModalCustom>
  );
};

export default WhitelistCard;

const ModalAddDni = ({ open, setOpen, record }) => {
  const [newDNIorPass, setNewDNIorPass] = useState("");
  const [update, { loading, loaded, error }] = useUpdate();
  const [tipo, setTipo] = useState("");
  const refresh = useRefresh();
  const handleAddDni = () => {
    if (tipo) {
      if (tipo === "DNI") {
        if (newDNIorPass?.length >= 7 && newDNIorPass?.length <= 8) {
          const newWhitelist =
            record.whitelist !== null ? record.whitelist : [];
          const hasDNI = newWhitelist.find((el) => el == newDNIorPass);
          if (!hasDNI) {
            newWhitelist.push(newDNIorPass);
            update("corporativo", {
              id: record.id,
              data: { whitelist: newWhitelist },
              previousData: record,
            });
            createNotification("success", "DNI agregado correctamente.");
          } else {
            createNotification("info", "Ya existe el DNI en esta whitelist");
          }
        } else {
          createNotification("error", "El DNI debe contener 7 u 8 caracteres");
        }
      } else {
        if (newDNIorPass?.length >= 5 && newDNIorPass?.length <= 12) {
          const newWhitelist =
            record.whitelist !== null ? record.whitelist : [];
          const hasDNI = newWhitelist.find((el) => el == newDNIorPass);
          if (!hasDNI) {
            newWhitelist.push(newDNIorPass);
            update("corporativo", {
              id: record.id,
              data: { whitelist: newWhitelist },
              previousData: record,
            });
            createNotification("success", "Pasaporte agregado correctamente.");
          } else {
            createNotification("info", "Ya existe Pasaporte en esta whitelist");
          }
        } else {
          createNotification(
            "error",
            "El Pasaporte no debe superar los 12 caracteres"
          );
        }
      }
    }
  };
  useEffect(() => {
    if (loaded) {
      refresh();
      setOpen(false);
      createNotification("success", "DNI agregado con éxito");
    }
    if (error) {
      createNotification(
        "error",
        "No se pudo cargar el DNI, por favor intenta de nuevo",
        () => handleClickCopy({ ...record?.id, newDNIorPass })
      );
      record?.whitelist?.pop();
    }
  }, [loaded, error, loading]);

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };
  //KEVIN : Tipo de documento:
  const handlerTipoDoc = (e) => {
    setTipo(e.target.value);
    setNewDNIorPass(e.target.value);
  };

  useEffect(() => {
    setNewDNIorPass("");
    setTipo("");
  }, [open]);
  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      onClickAction={handleAddDni}
      disabledAction={loading}
      textAction={"Agregar"}
      startIcon={
        loading ? (
          <CircularProgress style={{ width: "18px", height: "18px" }} />
        ) : (
          <AddOutlinedIcon />
        )
      }
      showButtons
      title="Agregar nuevo DNI / Pasaporte"
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "10px",
          gap: "20px",
        }}
      >
        <TextFieldMui
          label="Tipo"
          variant="outlined"
          size="small"
          select
          SelectProps={{
            sx: { minWidth: "5em" },
            onChange: handlerTipoDoc,
            autoWidth: true,
          }}
        >
          <MenuItem value={"DNI"}>DNI</MenuItem>
          <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
        </TextFieldMui>
        <Tooltip
          placement="top"
          title={tipo?.length > 0 ? "" : "Seleccione un tipo"}
        >
          <TextFieldMui
            variant="outlined"
            size="small"
            label={tipo}
            disabled={tipo?.length > 0 ? false : true}
            onChange={(e) => setNewDNIorPass(e.target.value)}
            type={tipo === "DNI" ? "number" : "text"}
          />
        </Tooltip>
      </Box>
    </ModalCustom>
  );
};
