import { createContext, useEffect, useState } from "react";

let isDisabledNext = false;
let isLoadingBlur = false;

let dispatchFieldError = [];

export const disabledTabContext = createContext({
  setIsDisabledTab: (fieldCheck) => {
    isDisabledNext = fieldCheck;
  },
  getIsDisabled: () => {
    return isDisabledNext;
  },
  setIsLoading: (fieldCheck) => {
    isLoadingBlur = fieldCheck;
  },
  getIsLoading: () => {
    return isLoadingBlur;
  },
  getDispatchField: (fieldValue) => {
    dispatchFieldError.find((item) => {
      if (item === fieldValue) return;
    });
    dispatchFieldError.push(fieldValue);
    return;
  },
  dispatchField: () => {
    return dispatchFieldError;
  },
  clearDispatchField: (fieldValue) => {
    return dispatchFieldError.filter((item) => {
      item !== fieldValue;
    });
  },
});

const DispatchErrorContext = createContext({});

const DispatchErrorProvider = (props) => {
  const [fieldErrors, setFieldErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [updatedMerchant, setUpdatedMerchant] = useState(null);
  const [planCadena, setPlanCadena] = useState(false);

  useEffect(() => {
    if (fieldErrors.length === 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    return () => {
      setIsDisabled(false);
    };
  }, [fieldErrors]);

  const fieldContext = {
    updatedMerchant: updatedMerchant,
    errors: fieldErrors,
    disabledSave: disabledSave,
    isCadena: planCadena,
    setUpdatedMerchant: (e) => {
      setUpdatedMerchant(e);
    },
    setIsDisabledTab: (fieldCheck) => {
      setIsDisabled(fieldCheck);
    },
    getIsDisabled: () => {
      return isDisabled;
    },
    setIsCadena: (boolean) => {
      setPlanCadena(boolean);
    },
    setIsLoading: (fieldCheck) => {
      isLoadingBlur = fieldCheck;
    },
    getIsLoading: () => {
      return isLoadingBlur;
    },
    getDispatchField: (fieldValue) => {
      let repeat = fieldErrors.find((item) => item === fieldValue);
      console.log(repeat);
      if (repeat === undefined) {
        setFieldErrors((f) => [...f, fieldValue]);
        return;
      }
      return;
    },
    setDisableSaveButton: (boolean) => {
      setDisabledSave(boolean);
    },
    dispatchField: () => {
      return fieldErrors;
    },
    clearDispatchField: (fieldValue) => {
      let newFieldErrors = fieldErrors.filter((item) => item !== fieldValue);
      return setFieldErrors(newFieldErrors);
    },
    clearAllDispatchFields: () => {
      return setFieldErrors([]);
    },
  };

  return (
    <DispatchErrorContext.Provider value={fieldContext}>
      {props.children}
    </DispatchErrorContext.Provider>
  );
};

export { DispatchErrorContext, DispatchErrorProvider };
