import {
  FormControlLabel,
  Switch,
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  Popover,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutocompleteInput,
  required,
  useDataProvider,
  useRecordContext,
  choices,
  useChoices,
} from "react-admin";
import SectionForm from "../../../Components/Form/SectionForm";
import {
  createNotification,
  getPermissions,
  isUserSC,
} from "../../helpers/helpFunctions";
import { useQuery } from "react-query";
import useCorpContext from "./useCorpContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  merchantCadena,
  merchantCentral,
  merchantContext,
} from "../../../DataProvider";
import { CorpContext, CorpProvider } from "./ContextCorp";
import { useForm, useFormState } from "react-final-form";
import { useDebounce } from "../../../Hooks/useDebounce";
import { grey } from "@mui/material/colors";
import { DispatchErrorContext } from "../../../Context/menuContext";
import { PlanCorporativo } from "./PlanCorporativo";

const planSocio = (abovePlan, setPlanCadena) => {
  const record = useRecordContext();
  const isEdit = record?.documento ? true : false;

  const [planes, setPlanes] = useState(abovePlan ? [abovePlan] : null);
  const [switchMulti, setSwitchMulti] = useState(
    abovePlan ? { name: abovePlan?.["tipo-de-plan"] } : { name: "local" }
  );
  const [cobroPlan, setCobroPlan] = useState("");
  const [notification, setNotification] = useState(null);
  const [merchantId, setMerchantId] = useState(abovePlan?.merchant_id);
  const [methodMessage, setMethodMessage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(
    record?.preferred_payment_method
      ? record.preferred_payment_method
      : "efectivo"
  );
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [corporativo, setCorporativo] = useState(null);
  const [bool, setBool] = useState(Boolean(record?.plan_corporativo));
  const dataProvider = useDataProvider();
  const efectivo =
    record?.preferred_payment_method === "efectivo" ? true : false;
  const tarjeta = record?.preferred_payment_method === "tarjeta" ? true : false;
  const permissions = getPermissions();
  const hasCards = record?.cards?.length > 0;
  const paymentMethodsOptions = ["efectivo", "tarjeta"];

  const { getMerchant } = useContext(merchantContext);
  const { setIsCadena, clearAllDispatchFields, setDisableSaveButton } =
    useContext(DispatchErrorContext);
  const { newPlan, back, getBack } = useContext(CorpContext);

  useEffect(() => {
    return () => {
      setBool(Boolean(record?.plan_corporativo));
    };
  }, [clearAllDispatchFields]);

  useEffect(() => {
    if (selectedPlan?.flex) {
      localStorage.setItem("newFlex", true);
    } else {
      localStorage.removeItem("newFlex");
    }
  }, [selectedPlan]);

  useEffect(() => {
    return () => {
      setMerchantId(abovePlan?.merchant_id);
      setMethodMessage(null);
      setPaymentMethod(
        record?.preferred_payment_method
          ? record.preferred_payment_method
          : "efectivo"
      );
      setSwitchMulti(
        abovePlan ? { name: abovePlan["tipo-de-plan"] } : { name: "local" }
      );
    };
  }, [record]);

  const params = useMemo(() => {
    switch (true) {
      case efectivo:
        return {
          pagination: { page: 1, perPage: 9999 },
          sort: { field: "id", order: "DESC" },
          filter: { archivado: false, "payment_methods.efectivo": efectivo },
        };
      case tarjeta:
        return {
          pagination: { page: 1, perPage: 9999 },
          sort: { field: "id", order: "DESC" },
          filter: { archivado: false, "payment_methods.tarjeta": tarjeta },
        };

      default:
        return {
          pagination: { page: 1, perPage: 9999 },
          sort: { field: "id", order: "DESC" },
          filter: { archivado: false, "payment_methods.efectivo": true },
        };
    }
  }, [efectivo, tarjeta]);

  const planFilter = useMemo(() => {
    if (switchMulti.name === "local") return { ["tipo-de-plan"]: "local" };
    if (switchMulti.name === "cadena") return { ["tipo-de-plan"]: "cadena" };
    return { ["tipo-de-plan"]: "central" };
  }, [switchMulti, record]);

  const { refetch, isFetching } = useQuery(
    ["planes", record, abovePlan],
    () =>
      dataProvider.getList("planes", {
        ...params,
        filter: { cobro: abovePlan?.cobro, ...planFilter, ...params.filter },
      }),
    {
      onSuccess: ({ data }) => {
        setPlanes(abovePlan ? [abovePlan, ...data] : [...data]);
        setDisableSaveButton(false);
        record?.active_plan_id &&
          data.map((e) => {
            if (e.id === record?.active_plan_id) {
              !selectedPlan && setSelectedPlan(e);
              setMerchantId(e?.merchant_id);
              setCobroPlan(e.cobro);
              if (e["tipo-de-plan"] !== abovePlan["tipo-de-plan"])
                setSwitchMulti({ name: e?.["tipo-de-plan"] });
              // setSwitchMulti(false);
            }
          });
      },
      onError: (err) => {
        setDisableSaveButton(false);
      },
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [switchMulti]);

  const checkPlanValidations = (value) => {
    let message = null;
    if (!value) {
      message = "El campo del plan es olbigatorio";
    }
    planes &&
      planes?.map((e) => {
        if (e?.id === value) {
          if (!!cobroPlan && e.cobro !== cobroPlan) {
            console.log(e.cobro, cobroPlan);

            message = "No se pueden cambiar planes de distinto tipo de cobro.";
          } else {
            message = null;
          }
        }
      });
    if (message) {
      setNotification(message);
      return message;
    } else {
      setNotification(null);
      return undefined;
    }
  };

  useEffect(() => {
    if (!abovePlan) return;
    if (record && abovePlan) {
      let planesFound = planes?.find(
        (item) => item?.id === record?.active_plan_id
      );
      if (Boolean(planes) && !planesFound) {
        setPlanes([...planes, abovePlan]);
      }
    }
  }, [planes]);

  useEffect(() => {
    setTimeout(() => {
      if (notification) {
        createNotification("warning", notification);
      }
    }, 200);

    return () => {
      return setNotification(null);
    };
  }, [selectedPlan, back]);

  useEffect(() => {
    if (newPlan !== "") {
      let found = !!planes?.find((item) => item.id === newPlan?.id);

      if (!found) {
        getBack(false);
        setSelectedPlan(
          planes?.find((item) => item.id === record?.active_plan_id)
        );
        return;
      } else {
        setSwitchMulti({ name: newPlan["tipo-de-plan"] });
        setSelectedPlan(newPlan);
      }
    }
    return () => {
      setNotification(null);
      return;
    };
  }, [newPlan]);
  // useEffect(() => {
  //   if (setPlanCadena) {
  //     if (!isUserSC(permissions) && selectedPlan["tipo-de-plan"] === "cadena") {
  //       console.log("planCadena");
  //       setPlanCadena(true);
  //       setIsCadena(true);
  //     } else {
  //       setPlanCadena(false);
  //       setIsCadena(false);
  //     }
  //   }
  // }, [selectedPlan]);

  const handlePaymentMethod = (e) => {
    setPaymentMethod(paymentMethodsOptions[e]);
    if (record?.preferred_payment_method !== paymentMethodsOptions[e]) {
      if (record?.preferred_payment_method === "efectivo" && hasCards) {
        setMethodMessage(
          "Al guardar se utilizaran las tarjetas vigentes del socio para abonar pagos futuros."
        );
      } else {
        setMethodMessage(
          "Al guardar el método de cobro pasara a ser en efectivo. ¿Estás seguro?"
        );
      }
    } else {
      setMethodMessage(null);
    }
  };

  const planesFilter = useMemo(() => {
    const merchantId = getMerchant()?.id;
    const isCentral = getMerchant()?.negocio_central;
    const isCadena = getMerchant()?.id !== merchantCadena;

    switch (true) {
      case merchantId === "":
        return [{ name: "local" }, { name: "central" }, { name: "cadena" }];

      case merchantId === merchantCadena:
        return [{ name: "cadena" }];

      case isCentral && !isCadena:
        return [{ name: "central" }];

      case !isCentral:
        return [{ name: "local" }, { name: "cadena" }];

      default:
        return [];
    }
  }, [getMerchant().id]);

  const handleTab = (e) => {
    setSwitchMulti(planesFilter[e]);
  };

  const handleSelectedPlan = useCallback(
    (e, v) => {
      if (typeof e === "string") {
        let plan = planes?.find((item) => item.id === e);
        setSelectedPlan(planes?.find((item) => item.id === e));
        console.log(plan["tipo-de-plan"]);
        setIsCadena(Boolean(plan["tipo-de-plan"] === "cadena"));
      }
    },
    [planes]
  );

  const data = [
    planesFilter?.length > 1 &&
      !record?.plan_corporativo && {
        // source: '',
        type: "radioSelect",
        label: "Filtro de planes",
        onClick: handleTab,
        disabled: planesFilter?.length <= 1 || bool,
        choices: planesFilter,
        xs: 12,
      },
    planes && !isFetching
      ? {
          source: "active_plan_id",
          type: "autocomplete",
          getOptionLabel: (option) => option.name,
          validate: {
            required: "Falta el campo Plan",
            validate: checkPlanValidations,
          },
          onChange: handleSelectedPlan,
          value: selectedPlan,
          label: "Plan",
          tooltip:
            record?.status === "inactivo"
              ? "No se puede cambiar el plan de un socio inactivo"
              : bool
              ? "No se puede cambiar el plan del socio mientras tenga una campaña"
              : "",
          placement: "bottom",
          disabled: bool || record?.status === "inactivo",
          choices: planes,
          variant: "outlined",
          optionLabel: "name",
          saveValue: "id",
          optionValue: "id",
          xs: 12,
        }
      : {
          custom: true,
          component: <Skeleton height="60px" />,
          xs: 12,
        },
    isEdit && {
      source: "last_subscription_date",
      label: "Alta",
      validate: { required: "El campo es requerido" },
      disabled: isEdit,
      xs: 6,
    },
    isEdit && {
      source: "fecha_vigencia",
      label: "Vigencia",
      disabled: true,
      xs: 6,
    },

    {
      source: "status",
      value: record?.status ? record?.status : "activo",
      label: "status",
      displayNone: true,
      style: { display: "none" },
    },
    {
      custom: true,
      component: record?.plan_corporativo && (
        <PlanCorporativo
          selectedPlan={selectedPlan}
          merchantId={merchantId}
          setBool={setBool}
          bool={bool}
          setCorporativo={setCorporativo}
          corporativo={corporativo}
        />
      ),
      xs: 12,
    },
    record?.preferred_payment_method && {
      source: "preferred_payment_method",
      tooltip:
        record?.preferred_payment_method === "efectivo" && !hasCards
          ? "Si deseas cambiar el método de pago, deberas dirigirte al portal socio para poder ingresar los datos de tarjeta."
          : "",
      type: "radioSelect",
      disabled: record?.preferred_payment_method === "efectivo" && !hasCards,
      onClick: (e) => handlePaymentMethod(e),
      choices: paymentMethodsOptions,
      label: "Método de pago",
      checked: paymentMethodsOptions.indexOf(paymentMethod),
      value: paymentMethod,
      xs: 12,
    },
    methodMessage
      ? {
          custom: true,
          component: <FormHelperText>{methodMessage}</FormHelperText>,
          xs: 12,
        }
      : null,
    record?.payment_type && {
      source: "payment_type",
      value: record?.payment_type,
      displayNone: true,
    },
    {
      source: "plan_corporativo",
      displayNone: true,
      value: bool ? corporativo?.id : null,
    },
  ];

  return data;
};
export default planSocio;
